var menuAnimation = false
var lastScrollTop = 0
var isMenuExtended = false
var galleryImages

window.addEventListener('load', function () {
  $('.content').fadeIn()

  lastScrollTop = window.scrollY
  setMenu()

  $$('img').forEach((img) => {
    img.draggable = false
  })

  const setMenuEvents = ['resize', 'scroll']
  setMenuEvents.forEach((event) => {
    window.addEventListener(event, setMenu)
  })

  $('.header').addEventListener('transitionstart', (e) => {
    menuAnimation = true
  })

  $('.header').addEventListener('transitionend', (e) => {
    menuAnimation = false
    setMenu()
  })

  $('.menu-item').forEach((item) => {
    item.addEventListener('click', () => {
      if (!isDesktop()) toggleMenu(false)
    })
  })

  $$('.gallery-link').forEach((g) => {
    g.addEventListener('click', showGallery)
  })

  if ($('.bounce-arrow')) {
    $('.bounce-arrow').addEventListener('click', function () {
      setScrollTop($(this.data('target')), 0)
    })
  }

  $('.hamburger').addEventListener('click', toggleMenu)
})

window.addEventListener('scroll', setHideMenu)

async function ifPhotoExists(src) {
  return fetch(src, { method: 'HEAD' }).then((res) => {
    return res.ok
  })
}

async function showGallery(g) {
  const gallery = document.createElement('div')
  const sourceDir = g.target.data('source')

  const spaceAbove = document.createElement('div')
  const spaceBelow = document.createElement('div')
  spaceAbove.classList.add('gallery-spacer', 'above')
  spaceBelow.classList.add('gallery-spacer', 'below')

  gallery.appendChild(spaceAbove)

  for (let i = 1; i <= 100; i++) {
    const src = `${sourceDir}${i}.jpg`
    if (!(await ifPhotoExists(src))) {
      if (i === 1) {
        return // dont do anything if no photos were provided
      } else {
        galleryImages = i - 1
        break // break and create if at least one photo exists
      }
    }

    const img = new Image()
    img.src = src
    img.addEventListener('click', (e) => {
      if (isDesktop()) {
        e.stopPropagation()
        openPreview(img)
      }
    })
    gallery.appendChild(img)
  }

  gallery.appendChild(spaceBelow)

  $('body').classList.add('noscroll')
  $('html').classList.add('noscroll')

  gallery.classList.add('gallery')
  gallery.appendChild
  gallery.addEventListener('click', () => {
    closeGallery(gallery)
  })
  gallery.appendChild(getGalleryX(closeGallery, gallery))
  document.body.appendChild(gallery)
  setTimeout(() => gallery.classList.add('opened'), 200)
}

export function openPreview(img) {
  img.classList.add('gallery-image')
  const preview = document.createElement('div')
  preview.classList.add('gallery-fullscreen')
  preview.appendChild(img.cloneNode())
  preview.appendChild(getGalleryArrow('left'))
  preview.appendChild(getGalleryArrow('right'))
  preview.appendChild(getGalleryX(closePreview, preview, true))
  preview.addEventListener('click', () => {
    closePreview(preview)
  })
  document.body.appendChild(preview)
  setTimeout(() => preview.classList.add('opened'), 200)
}

export function closePreview(preview) {
  preview.classList.remove('opened')
  setTimeout(() => preview.remove(), 500)
}

function closeGallery(gallery) {
  gallery.classList.remove('opened')
  setTimeout(() => gallery.remove(), 500)
  $('body').classList.remove('noscroll')
  $('html').classList.remove('noscroll')
}

function getGalleryArrow(direction) {
  const arrow = document.createElement('img')
  arrow.src = './img/icons/arrow.svg'
  arrow.classList.add('gallery-arrow')
  arrow.classList.add(`arrow-${direction}`)
  arrow.addEventListener('click', async (e) => {
    e.stopPropagation()
    const img = document.querySelector('.gallery-fullscreen > img')

    let srcPath = img.src.split('/')
    const currentIndex = parseInt(srcPath.pop().charAt(0))
    srcPath = srcPath.join('/') + '/'
    const nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1
    const resetIndex = direction === 'left' ? galleryImages : 1
    const nextSrc = (await ifPhotoExists(srcPath + nextIndex + '.jpg')) ? nextIndex : resetIndex
    img.src = srcPath + nextSrc + '.jpg'
  })
  return arrow
}

export function getGalleryX(onClick, param, inversed = false) {
  const x = document.createElement('img')
  x.src = './img/icons/x.svg'
  x.classList.add('gallery-x')
  x.addEventListener('click', () => onClick(param))
  if (inversed) {
    x.classList.add('inversed')
  }
  return x
}

function toggleMenu(show) {
  if (!isMenuExtended || (typeof show === 'boolean' && show)) {
    $('.menu').style.left = 0
    $('.hamburger').classList.add('is-active')
  } else {
    $('.menu').style.left = -0.6 * window.innerWidth + 'px'
    $('.hamburger').classList.remove('is-active')
  }
  isMenuExtended = !isMenuExtended
}

function setHideMenu() {
  if (!isDesktop() && isMenuExtended) return

  let scrollTop = window.scrollY
  if (Math.abs(scrollTop - lastScrollTop) > 70) {
    const el = $('.header')

    if (scrollTop > lastScrollTop) {
      setTimeout(() => {
        el.style.top = `-${el.clientHeight + 5}px`
      }, 400)
    } else {
      el.style.top = '0px'
    }
    lastScrollTop = scrollTop
  }
}

function setMenu() {
  const scrollTop = window.scrollY
  if (isDesktop() && !menuAnimation) {
    if (scrollTop === 0) {
      if (!$('.header').classList.contains('ignore-stick')) {
        $('.header').classList.remove('sticked')
      }
    } else {
      if (!$('.header').classList.contains('sticked')) {
        $('.header').classList.add('sticked')
      }
    }
  }
}

export function $$(selector, parent) {
  return $(selector, parent, true)
}

export function $(selector, parent = document, forceArray = false) {
  const nodes = parent.querySelectorAll(selector)
  const nodesArray = Array.from(nodes)

  if (nodes.length === 0 && !forceArray) return undefined
  if (forceArray) {
    return nodesArray
  } else {
    return nodesArray.length == 1 ? nodesArray[0] : nodesArray
  }
}

export function isDesktop() {
  return window.innerWidth > 992
}

export function isBoolean(val) {
  return val === 'true' || val === 'false'
}

export function isNumber(val) {
  return !isNaN(parseInt(val))
}

export async function setScrollTop(val, margin = isDesktop() ? 150 : 70) {
  if (typeof val !== 'number') {
    val = val.getBoundingClientRect().top + document.documentElement.scrollTop
  }
  console.log(val)
  window.scrollTo(0, val, { top: margin })
}

Element.prototype.fadeIn = function (duration = 400, displayMode = false, limit = 1, callback) {
  const currentTransiton = this.style.transition

  this.classList.add('fading')
  this.style.transition = `opacity ${duration}ms ease-in`
  if (displayMode !== false) {
    this.style.display = displayMode
  }

  setTimeout(() => {
    this.style.opacity = limit
  }, 100)

  setTimeout(() => {
    this.classList.remove('fading')
    this.style.transition = currentTransiton

    if (typeof callback == 'function') {
      callback()
    }
  }, duration + 200)
}

Element.prototype.fadeOut = function (duration = 400, disable = false, limit = 0, callback) {
  const currentTransiton = this.style.transition

  this.classList.add('fading')
  this.style.transition = `opacity ${duration}ms ease-out`
  setTimeout(() => {
    this.style.opacity = limit
  }, 100)

  setTimeout(() => {
    this.classList.remove('fading')
    this.style.transition = currentTransiton
    if (disable) {
      this.style.display = 'none'
    }
    if (typeof callback == 'function') {
      callback()
    }
  }, duration + 200)
}

Element.prototype.data = function (key, value) {
  if (value === undefined) {
    const rawVal = this.getAttribute(`data-${key}`)
    let returnVal = rawVal

    if (isNumber(rawVal)) returnVal = parseInt(rawVal)
    if (isBoolean(rawVal)) returnVal = rawVal == 'true'

    return returnVal
  } else {
    return this.setAttribute(`data-${key}`, value)
  }
}
