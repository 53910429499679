import { $, isDesktop } from './common.js'
import tsParticles from './tsparticles.js'

window.addEventListener('load', () => {
  if (!isDesktop()) {
    $('#particles-js').style.height = window.innerHeight - $('.header').clientHeight + 'px'
  }

  tsParticles
    .loadJSON('particles-js', '/config/particles-config.json')
    .then((container) => {
      particlesRefresh(container)
    })
    .catch((e) => {
      console.error('Could not load particles config')
    })
})

$('#particles-js').addEventListener('contextmenu', (e) => {
  e.preventDefault()
})

function particlesRefresh(container) {
  const canvas = container.canvas
  const options = container.options
  const pxRatio = container.retina.pixelRatio

  canvas.size.width = canvas.element.offsetWidth * pxRatio
  canvas.size.height = canvas.element.offsetHeight * pxRatio
  canvas.element.width = canvas.size.width
  canvas.element.height = canvas.size.height

  if (!options.particles.move.enable) {
    container.particles.redraw()
  }

  container.densityAutoParticles()

  for (const [, plugin] of container.plugins) {
    if (plugin.resize !== undefined) {
      plugin.resize()
    }
  }
}
